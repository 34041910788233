<template>
  <section id="dashboard-ecommerce">
    
    <!--   <b-row class="match-height">
      <b-col md="12">
        <SynchronizedChart />
      </b-col>
    </b-row> -->

    <b-row class="match-height">
      <b-col md="6">

        <b-row>
          <b-col md="12">
            <b-card
              v-if="statistics.length > 0"
              no-body
              class="card-statistics"
            >
              <b-card-body class="statistics-body">
                <b-card-title>Statistics</b-card-title>
                <b-row>
                  <b-col
                    v-for="(item,index) in statistics"
                    :key="item.icon + index"
                    xl="6"
                    md="6"
                    sm="6"
                    :class="item.customClass +' mt-1'"
                  >
                    <b-media no-body>
                      <b-media-aside

                        class="mr-2"
                      >
                        <b-avatar
                          size="48"
                          :variant="item.color"
                        >
                          <feather-icon
                            size="24"
                            :icon="item.icon"
                          />
                        </b-avatar>
                      </b-media-aside>
                      <b-media-body class="my-auto">
                        <h4 class="font-weight-bolder mb-0">
                          {{ item.title }}
                        </h4>
                        <b-card-text class="font-small-3 mb-0">
                          {{ item.subtitle }}
                        </b-card-text>
                      </b-media-body>
                    </b-media>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-card
              no-body
              v-if="sr_data != null"
            >
              <!-- title and dropdown -->
              <b-card-header class="pb-0">
                <b-card-title>Service Request</b-card-title>
                <b-dropdown
                  no-caret
                  right
                  :text="srText"
                  variant="transparent"
                  class="chart-dropdown"
                  toggle-class="p-50"
                  size="sm"
                >
                  <b-dropdown-item
                    v-for="day in sr_days"
                    :key="day"
                    @click="srRecord(day)"
                  >
                    {{ day }}
                  </b-dropdown-item>
                </b-dropdown>
              </b-card-header>
              <!--/ title and dropdown -->

              <b-card-body>
                <b-row>
                  <b-col
                    sm="2"
                    class="d-flex flex-column flex-wrap text-center"
                  >
                    <h1 class="font-large-2 font-weight-bolder mt-2 mb-0">
                      {{ sr_data.total }}
                    </h1>
                    
                  </b-col>

                  <!-- chart -->
                  <b-col
                    sm="8"
                    class="d-flex justify-content-center"
                  >

                    <!-- apex chart -->
                    <vue-apex-charts
                      type="radialBar"
                      height="270"
                      :options="supportTrackerRadialBar.chartOptions"
                      :series="sr_data.supportTrackerRadialBar.series"
                    />
                  </b-col>
                  <!--/ chart -->
                </b-row>

                <!-- chart info -->
                <div class="d-flex justify-content-between">
                  <div class="text-center">
                    <b-card-text class="mb-50">
                      New Tasks
                    </b-card-text>
                    <span class="font-large-1 font-weight-bold">{{ sr_data.new_task }}</span>
                  </div>
                  <div class="text-center">
                    <b-card-text class="mb-50">
                      Open Tasks
                    </b-card-text>
                    <span class="font-large-1 font-weight-bold">{{ sr_data.open }}</span>
                  </div>
                  <div class="text-center">
                    <b-card-text class="mb-50">
                      Closed Tasks
                    </b-card-text>
                    <span class="font-large-1 font-weight-bold">{{ sr_data.closed }}</span>
                  </div>
                </div>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="6">
        <b-card no-body>
          <b-card-header>
            
            <div>
              <b-card-title class="mb-1">
                Job Completed
              </b-card-title>
              
            </div>
            <!--/ title and subtitle -->

            <!-- datepicker -->
            <div class="d-flex align-items-center">
              <feather-icon
                icon="CalendarIcon"
                size="16"
              />
              
              <flat-pickr
                v-model="sr_range"
                :config="srconfig"
                class="form-control flat-picker bg-transparent border-0 shadow-none"
                placeholder="DD MMM YYYY"
                style="width:220px"
                 @on-change="srJobCompleteRecord"
              />

            </div>
            <!-- datepicker -->
          </b-card-header>
          <b-card-body>
            
            <vue-apex-charts
              type="area"
              height="450"
              ref="srjcchart"
              :options="lineAreaChartSpline.chartOptions"
              :series="lineAreaChartSpline.series"
            />
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col md="6">

        <b-card no-body>
          <b-card-header>
            <b-card-title>Top Users By </b-card-title>

            <b-dropdown
              no-caret
              right
              :text="tu_text"
              variant="transparent"
              class="chart-dropdown"
              toggle-class="p-50"
              size="sm"
            >
              <b-dropdown-item
                v-for="day in tu_textArray"
                :key="day"
                @click="noOfJobClosed(day)"
              >
                {{ day }}
              </b-dropdown-item>
            </b-dropdown>
            <!-- datepicker -->
            <div class="d-flex align-items-center">
              <feather-icon
                icon="CalendarIcon"
                size="16"
              />
              <flat-pickr
                v-model="cj_range"
                :config="srconfig"
                class="form-control flat-picker bg-transparent border-0 shadow-none"
                placeholder="DD MMM YYYY"
                style="width:220px"
                @on-change="noOfJobClosed(tu_text)"
              />
            </div>
            <!-- datepicker -->
          </b-card-header>

          <!-- chart -->
          <b-card-body>
            <chartjs-component-bar-chart
              :height="400"
              :data="closeJobBarChart.data"
              :options="closeJobBarChart.options"
            />
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="6" v-if="custom_form_access">

        <CustomFormGraph />
        
      </b-col>
    </b-row>
    <!-- <b-row class="match-height">
      <b-col md="6">
        <ZoommableGraph />
      </b-col>
    </b-row> -->
  </section>
</template>

<script>
import { BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody, BDropdown, BDropdownItem,BCardSubTitle } from 'bootstrap-vue'

//import { getUserData } from '@/auth/utils'
import { GET_API,POST_API } from "../../store/actions.type"
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import flatPickr from 'vue-flatpickr-component'
import moment from "moment-timezone";
import ChartjsComponentBarChart from './charts-components/ChartjsComponentBarChart.vue'
import Bus from "../../event-bus";
import CustomFormGraph from './customFormGraph.vue';
import ZoommableGraph from './zoommableChart.vue';
import SynchronizedChart from './SynchronizedChart.vue';

export default {
  components: {
    BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,VueApexCharts,BDropdown, BDropdownItem,flatPickr,BCardSubTitle,ChartjsComponentBarChart,CustomFormGraph,ZoommableGraph, SynchronizedChart

  },
  data() {
    return {
      custom_form_access : false,
      statistics: [],
      srText:'Last 7 Days',
      sr_days: [ 
        "Last 7 Days",
        "This Month", 
        "Last Month", 
        "Last Year" 
      ],

      sr_data:null,
      supportTrackerRadialBar: {
        chartOptions: {
          plotOptions: {
            radialBar: {
              size: 150,
              offsetY: 20,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: '65%',
              },
              track: {
                background: '#fff',
                strokeWidth: '100%',
              },
              dataLabels: {
                name: {
                  offsetY: -5,
                  color: '#5e5873',
                  fontSize: '1rem',
                },
                value: {
                  offsetY: 15,
                  color: '#5e5873',
                  fontSize: '1.714rem',
                },
              },
            },
          },
          colors: [$themeColors.danger],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.primary],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            dashArray: 8,
          },
          labels: ['Closed Tasks'],
        },
      },
      //3rd Graph
      
      srconfig:{ 
        mode: 'range',
        dateFormat: 'd M Y',
        defaultDate: [
          moment.utc().subtract(7,'days').format('DD MMM YYYY'),
          moment.utc().format('DD MMM YYYY')
        ]
      },

      sr_range:moment.utc().subtract(7,'days').format('DD MMM YYYY') +' to '+moment.utc().format('DD MMM YYYY'),
      
      lineAreaChartSpline: { 
        "series": [], 
        "chartOptions": { 
          "xaxis": { 

              "categories": [], 
              //"convertedCatToNumeric": false 
          }, 
          "yaxis": {
            labels: {
              formatter: function(val) {
                return val.toFixed(0);
              }
            }
          }, 

          "chart": { 
              "toolbar": { 
                "show": false 
              } 
          }, 
            
          "dataLabels": { 
            "enabled": false 
          }, 
          "stroke": { 
            "show": false, 
            "curve": "straight" 
          }, 
          "legend": { 
            "show": true, 
            "position": "top", 
            "horizontalAlign": "left", 
            "fontSize": "14px", 
            "fontFamily": "Montserrat" 
          }, 
          "grid": { 
            "xaxis": { 
              "lines": { 
                "show": true 
              } 
            } 
          }, 
            
          "fill": { 
            "opacity": 1, 
            "type": "solid" 
          }, 
          "tooltip": { 
            "shared": false 
          }, 
          "colors": [ "#a4f8cd", "#60f2ca", "#2bdac7" ] 
        } 
      },
      //3rd Graph end
      //4th Graph
      cj_range:moment.utc().subtract(7,'days').format('DD MMM YYYY') +' to '+moment.utc().format('DD MMM YYYY'),
      closeJobBarChart: { 
        "data": {}, 
        "options": {} 
      },
      tu_text:'Closed Jobs',
      tu_textArray:[
        'Closed Jobs',
        'Service Request',
        'Defects',
        'Checklist'
      ]
    }
  },
  methods:{
    statisticsRecord(){
      return this.$store.dispatch(POST_API, {
         data:{

         },
         api: '/api/dashboard-statistics'
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
          } else {
              this.statistics = this.$store.getters.getResults.data;
              
              return this.statistics;
          }
      });
    },
    srRecord(interval){
      
      this.srText = interval;

      return this.$store.dispatch(POST_API, {
         data:{
            role:this.$store.getters.currentUser.role,
            om_sites:this.$store.getters.currentUser.om_sites,
            interval:interval
         },
         api: '/api/dashboard-sr-records'
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
          } else {
              this.sr_data = this.$store.getters.getResults.data;
              
              return this.sr_data;
          }
      });
    },
    srJobCompleteRecord(){
      
      return this.$store.dispatch(POST_API, {
         data:{
            role:this.$store.getters.currentUser.role,
            om_sites:this.$store.getters.currentUser.om_sites,
            range:this.sr_range
         },
         api: '/api/sr-job-completed'
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
          } else {
              var data = this.$store.getters.getResults.data;
              this.lineAreaChartSpline.series = data.series;
              //this.lineAreaChartSpline.chartOptions.xaxis= data.categories
              this.lineAreaChartSpline.chartOptions.xaxis.categories = data.categories;
              

              this.lineAreaChartSpline.chartOptions = {
                ...this.lineAreaChartSpline.chartOptions,
                ...{
                  xaxis: {
                    categories: data.categories 
                  }
                }
              };

              this.$refs.srjcchart.updateSeries(this.lineAreaChartSpline.series, true);
              this.$refs.srjcchart.updateOptions(this.lineAreaChartSpline.chartOptions, false, true);

              

              return this.lineAreaChartSpline;
          }
      });
    },
    noOfJobClosed(type){
      
      this.tu_text = type;
      
      return this.$store.dispatch(POST_API, {
         data:{
            role:this.$store.getters.currentUser.role,
            om_sites:this.$store.getters.currentUser.om_sites,
            range:this.cj_range,
            type:this.tu_text,
         },
         api: '/api/no-of-closed-jobs-by-users'
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
          } else {
              var data = this.$store.getters.getResults.data;

              var data = JSON.parse(data);
              var chartData = data.result;
              var options = data.options;
              
              Bus.$emit('closeJobBarChart',chartData,options);
          }
      });
    },
    checkCustomFormAccess(){
      var cf1 = false;
      var cf2 = false;

      this.$store.getters.currentUser.menu_permission.forEach(el => {
        if(el.level == 1 && el.title == 'Custom Forms'){
          cf1 = el.access;
        }
        if(el.level == 2 && el.title == 'Reports'){
          el.children.forEach(ch => {
            if(ch.title == 'Custom Forms'){
              cf2 = ch.access;
            } 
          })
        }
      });

      this.custom_form_access =  cf1 || cf2;
    }
  },
  mounted() {
    if(this.$route.name != this.setDefaultPage(this.$route.name)){
      this.$router.push({ name: this.setDefaultPage(this.$route.name)});
    }
    this.statisticsRecord();
    this.srRecord('Last 7 Days');
    this.srJobCompleteRecord();
    this.noOfJobClosed('Closed Jobs');
    this.checkCustomFormAccess();
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

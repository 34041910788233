<template>
  
  <div>
    
    <b-card no-body>
      <b-card-header>
        
            <div>
              <b-card-title class="mb-1">
                Custom Forms
              </b-card-title>
              
            </div>
            <!--/ title and subtitle -->

            <!-- datepicker -->
            <div class="d-flex align-items-center">
              <feather-icon
                icon="CalendarIcon"
                size="16"
              />
              
              <flat-pickr
                v-model="sr_range"
                :config="srconfig"
                class="form-control flat-picker bg-transparent border-0 shadow-none"
                placeholder="DD MMM YYYY"
                style="width:220px"
                @on-change="noOfCustomForm()"
              />

            </div>

        <!-- datepicker -->
      </b-card-header>
      <b-card-header class="pt-0">
        <!-- <b-dropdown
          
          right
          :text="selectedForm ? selectedForm.title : ''"
          class="chart-dropdown"
          toggle-class="p-50"
          size="sm"
          no-caret
          variant="warning"
        >
          <b-dropdown-item
            v-for="form in customForms"
            :key="form._id"
            @click="noOfCustomForm(form)"
          >
            {{ form.title }}
          </b-dropdown-item>
        </b-dropdown> -->

        <v-select
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="customForms"
          label="title"
          class="w-100"
          placeholder="Form"
          @input="noOfCustomForm"
          v-model="selectedForm"
          :clearable="false"
        />
          
      </b-card-header>
      
      <b-card-body>
        
        <vue-apex-charts
          type="area"
          height="450"
          ref="srjcchart"
          :options="lineAreaChartSpline.chartOptions"
          :series="lineAreaChartSpline.series"
        />
      </b-card-body>
    </b-card>
      

   </div> 
  
</template>

<script>
import { BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody, BDropdown, BDropdownItem,BCardSubTitle } from 'bootstrap-vue'

//import { getUserData } from '@/auth/utils'
import { GET_API,POST_API } from "../../store/actions.type"
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import flatPickr from 'vue-flatpickr-component'
import moment from "moment-timezone";
import ChartjsComponentBarChart from './charts-components/ChartjsComponentBarChart.vue'
import Bus from "../../event-bus";
import vSelect from 'vue-select'

export default {
  components: {
    BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,VueApexCharts,BDropdown, BDropdownItem,flatPickr,BCardSubTitle,ChartjsComponentBarChart,vSelect

  },
  data() {
    return {
      
      //3rd Graph
      
      srconfig:{ 
        mode: 'range',
        dateFormat: 'd M Y',
        defaultDate: [
          moment.utc().subtract(7,'days').format('DD MMM YYYY'),
          moment.utc().format('DD MMM YYYY')
        ]
      },

      sr_range:moment.utc().subtract(7,'days').format('DD MMM YYYY') +' to '+moment.utc().format('DD MMM YYYY'),
      
      lineAreaChartSpline: { 
        "series": [], 
        "chartOptions": { 
          "xaxis": { 

              "categories": [], 
              //"convertedCatToNumeric": false 
          }, 
          "yaxis": {
            labels: {
              formatter: function(val) {
                return val.toFixed(0);
              }
            }
          }, 

          "chart": { 
              "toolbar": { 
                "show": false 
              } 
          }, 
            
          "dataLabels": { 
            "enabled": false 
          }, 
          "stroke": { 
            "show": false, 
            "curve": "straight" 
          }, 
          "legend": { 
            "show": true, 
            "position": "top", 
            "horizontalAlign": "left", 
            "fontSize": "14px", 
            "fontFamily": "Montserrat" 
          }, 
          "grid": { 
            "xaxis": { 
              "lines": { 
                "show": true 
              } 
            } 
          }, 
            
          "fill": { 
            "opacity": 1, 
            "type": "solid" 
          }, 
          "tooltip": { 
            "shared": false 
          }, 
          "colors": [ "#a4f8cd"/*, "#60f2ca", "#2bdac7"*/ ] 
        } 
      },
      customForms : [],
      tu_text:'',
      selectedForm:null,
    }
  },
  methods:{
    
    getForms(){
      return this.$store.dispatch(POST_API, {
         data:{
           id: this.$store.getters.currentUser.organization,
           role: this.$store.getters.currentUser.role,
         },
         api: '/api/get-custom-forms-by-role'
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
          } else {
              var data = this.$store.getters.getResults.data.custom_forms;
              
              if (this.$store.getters.currentUser.role == 'administrator') {
                this.customForms = data;
              }else{
                var custom_forms = data;
                this.customForms = [];

                custom_forms.forEach(item => {
                  this.customForms.push(item.form);
                })
              } 
              this.customForms.sort((a, b) => (a.title > b.title ? 1 : -1)) 
              this.selectedForm = this.customForms[0];
              this.noOfCustomForm();
              return this.customForms;
          }
      });
    },

    noOfCustomForm(){
      

      return this.$store.dispatch(POST_API, {
         data:{
            role:this.$store.getters.currentUser.role,
            om_sites:this.$store.getters.currentUser.om_sites,
            range:this.sr_range,
            form:this.selectedForm
         },
         api: '/api/custom-form-created'
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
          } else {
              var data = this.$store.getters.getResults.data;
              this.lineAreaChartSpline.series = data.series;
              //this.lineAreaChartSpline.chartOptions.xaxis= data.categories
              this.lineAreaChartSpline.chartOptions.xaxis.categories = data.categories;
              

              this.lineAreaChartSpline.chartOptions = {
                ...this.lineAreaChartSpline.chartOptions,
                ...{
                  xaxis: {
                    categories: data.categories 
                  }
                }
              };

              this.$refs.srjcchart.updateSeries(this.lineAreaChartSpline.series, true);
              this.$refs.srjcchart.updateOptions(this.lineAreaChartSpline.chartOptions, false, true);

              

              return this.lineAreaChartSpline;
          }
      });
    }
  },
  mounted() {
    this.getForms();
    //this.noOfCustomForm(form);
    
    //this.srJobCompleteRecord();
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
